.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@font-face {
  font-family: neopro ;
  src: url('fonts/Neopro.OTF');
}


body{
  font-family: neopro !important;
}

.neo{
  font-family: neopro !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.link{
  text-decoration: none !important;
  color: black !important
}

.fullWidthExt{
  width: 100.5% !important
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.marginOff{
  margin: 0px !important;
}

.fullWidth1{
  width:100%
}

.whiteBg{
  background-color: white;
}

.rowPadding{
  padding: 10px 0px
}

.fullWidth{
  width:100.8%
}

.column-news {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 15px;
}

.news-tittle {
  flex: .7;
}

.elementor-element-populated {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin: 0px 0px 0px 0px;
margin-right: 0px;
margin-left: 0px;
  border-radius: 6px 6px 6px 6px;
}

.redText{
  color: red
}

.title {
  font-size: 26px;
  line-height: 3rem;
  color: #000;
  text-transform: capitalize;
}

.social_tabs {
  flex: 1;
    display: flex;
    align-items: center;
}

.social_tabs .divider {
  width: 1px;
  height: 16px;
  display: inline-block;
  background-color: #a8a8a8;
  margin: 0 24px;
}

.social_tabs a {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.list-news {
  display: flex;
  width: 50%;
  justify-content: end;
  flex: 2;
}

.list-view {
  margin-right: 15px;
}

.modal{ 
  --bs-modal-width: inherit !important;
  --bs-modal-height: 100vh !important
}

.modal-content{
  height: 100vh !important
}

.modal-dialog{
  margin: 0 !important;
}
.borderControl{
    border: 1px solid;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
}

.borderControl1{
  border: 1px solid white;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
}

.paddingOff{
  padding: 0px !important;
}

.recentPadding{
  padding-top: 2%;
}

.recentPadding1{

padding:2.5% !important
}

.paddingRLOff{
  padding-right:0px !important;
  padding-left: 0px !important;
}

.accordion{
  --bs-accordion-btn-icon-width: 1rem !important;
  --bs-accordion-btn-focus-border-color: transparent !important;
  --bs-accordion-btn-focus-box-shadow: transparent !important;
  --bs-accordion-border-color: transparent !important;
}

.owl-dots{
  position: static;
    transform: unset;
    padding: 0 40px;
    box-sizing: border-box;
}

.owl-dot {
  width: 30px;
  height: 30px;
  border-radius: 25px;
  margin-right: 14px;
  background-color: white;
  transition: .3s ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
    color: inherit;
    border: none;
    padding-top: 1% !important;
    font: inherit;
}

.owl-dot.active{
  background-color: black;
  color: white;
}

.owl-nav{
  position: static;
    bottom: 5%;
    width: 100%
}

.mobileSideNav{
  display:none
}
.owl-next{
  position: absolute;
    left: 114%;
    bottom: -1%;
    background: transparent !important;
    border: transparent !important; 
    font-size: 2.5rem;
    top: -13px;
}

button.owl-dot{
  background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
}

.owl-dot:before {
  color: #fff;
  font-size: 16px;
  font-family: neopro;
  line-height: 16px;
}

.owl-prev{
  position: absolute;
  left: -37%;
  background: transparent !important;
  border: transparent !important; 
  font-size: 2.5rem;
  bottom: -18px;
}



.main-nav{
  position: absolute;
  bottom: 28%;
  left: 50%;
  transform: translatex(-50%);
  z-index:10;
}

.eventDate{
  color: #54595F;
  font-size: 13px;
}


.elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 15px 0px 0px 0px;
}

.geelyHeading{
  font-size: 22px !important
}

.paddingRightOff{
  padding-right: 0px !important;
}

.clearSpace{
  margin-top: 20px !important;
}

.clearSpaceCarousel{
  margin-top: 70px !important;
}

.clearSpaceCarouselVideo{
  margin-top: 70px !important;
}

.clearSpaceCarouselBottomVideo{
  margin-bottom: 70px !important;
}

.clearSpaceCarouselBottom{
  margin-bottom: 70px !important;
}


.clearPadRight {
  padding: 70px 20px !important
}

.clearSpaceBottom{
  margin-bottom: 35px !important
}

.networkBox{
   height: 250px;
   padding: 34px !important;
   width:31% !important
}
.networkBox1{
  height: 250px;
  padding: 34px !important;
  background-color: #f1f1f1 !important;
  width: 35% !important
}

.first_layer{
      position: absolute;
      top: 100% !important;
      background-color: #fff;
      box-shadow: 0 12px 30px -2px rgb(0 0 0/10%);
      border-top: 3px solid #000000;
      z-index: 9999;
      left: 56% !important   
}

.elementor-divider { 
 border-style: solid;
 color: #E7E7E7;
 border-width: 0.2px;
}

.side_bar_close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

ul{
  padding:0px;
}

.elementor-post__read-more {
  background-color: #dcdcdc !important;
  border-radius: 4px;
  padding: 4px 10px;
  color: #4c4c4c !important;
  transition: .3s ease-in-out;
  font-size: 12px
}

.radi{
  border-radius: 20px;
}

.recentFont{
  font-size: 12px;
}


.sideNav{
   width: 25% !important;
   height: 100vh;
   background-color: white;
}

.side_inner_block {
  padding: 30px 40px;
  box-sizing: border-box;
}

.side_inner_block .tools_list li {
  padding-bottom: 15px;
}

.side_inner_block .tools_list li a {
  font-size: 14px;
  line-height: 22px;
  color: #000;
  text-decoration: none;
  display: inline-block;
  transition: .3s ease-in-out;
}

.side_inner_block .tools_list li a .tool_list_text {
  display: inline-block;
  padding-left: 15px;
  box-sizing: border-box;
}

.sideNavButton .side_inner_block .title {
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: 500 !important;
  color: #000000;
  margin: 0 0 20px;
}

.sideNavButton {
  font-size: 14px;
  line-height: 28px;
  font-weight: 500 !important;
  color: #000000;
  margin: 0 0 20px;
  text-decoration: none;
}

.sideNavButtonContainer{
  padding-right: 5%;
  padding-left: 10%;
}

.sideNavButtonContainerInner{

  padding-left: 8% !important;
}

.side_bottom_block {
  padding: 30px 40px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.side_bottom_block .social_links {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.side_bottom_block .text {
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  font-family: arial;
  text-align: center;
  padding-top: 15px;
}

.side_bottom_block .social_links li a {
  display: inline-block;
}

.side_bottom_block .social_links li a img {
  max-width: 100%;
  margin-right: 18px;
  box-sizing: border-box;
  vertical-align: middle;
}

.navLinkArr{
  position: relative;
    top: 22%;
}




.navHeight{
  height: 7vh
}

#serviceTab, #aboutTab, #mediaTab, #innovationTab,#mediaTab{
  display: none;
  
}



.first_layer ul{
  list-style: none;
  padding: 30px;
  box-sizing: border-box;
}

.first_layer ul .first_layer_item {
  min-width: 18em;
}

.first_layer ul .first_layer_item .first_layer_link {
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  color: #000;
  display: block;
  transition: all .3s ease-in-out;
  padding: 6px 15px;
  box-sizing: border-box;
  position: relative;
}


.second_layer{
  position: absolute;
  top: 100% !important;
  background-color: #fff;
  box-shadow: 0 12px 30px -2px rgb(0 0 0/10%);
  border-top: 3px solid #000000;
  z-index: 9999;
   
}

.second_layer ul{
  list-style: none;
  padding: 30px;
  box-sizing: border-box;
}

.second_layer ul .second_layer_item {
  min-width: 18em;
}

.second_layer ul .second_layer_item .second_layer_link {
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  color: #000;
  display: block;
  transition: all .3s ease-in-out;
  padding: 6px 15px;
  box-sizing: border-box;
  position: relative;
}


.third_layer{
  position: absolute;
  top: 100% !important;
  background-color: #fff;
  box-shadow: 0 12px 30px -2px rgb(0 0 0/10%);
  border-top: 3px solid #000000;
  z-index: 9999;
  left: 42%
   
}

.third_layer ul{
  list-style: none;
  padding: 30px;
  box-sizing: border-box;
}

.third_layer ul .third_layer_item {
  min-width: 18em;
}

.third_layer ul .third_layer_item .third_layer_link {
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  color: #000;
  display: block;
  transition: all .3s ease-in-out;
  padding: 6px 15px;
  box-sizing: border-box;
  position: relative;
}


.fourth_layer{
  position: absolute;
  top: 100% !important;
  background-color: #fff;
  box-shadow: 0 12px 30px -2px rgb(0 0 0/10%);
  border-top: 3px solid #000000;
  z-index: 9999;
  left: 62%;
   
}

.fourth_layer ul{
  list-style: none;
  padding: 30px;
  box-sizing: border-box;
}

.fourth_layer ul .fourth_layer_item {
  min-width: 18em;
}

.fourth_layer ul .fourth_layer_item .fourth_layer_link {
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  color: #000;
  display: block;
  transition: all .3s ease-in-out;
  padding: 6px 15px;
  box-sizing: border-box;
  position: relative;
}


.clearSpaceTop{
  margin-top: 35px !important
}

.clearSpaceBottomFooter{
  margin-bottom: 6px !important;
  font-size: 14px;
}

.intec{
 background: url('./images/intec.jpeg') center center;
 background-size: cover;
}

.design{
  background: url('./images/philosophy.jpeg') center center;
  background-size: cover;
 }

 .news{
  background: url('./images/history.jpeg') center center;
  background-size: cover;
 }

.roundButton{
  font-family: "neopro", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    fill:transparent;
    color: black;
    background-color: #00000000;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: black ;
    padding: 15px 46px 15px 46px;
    border-radius: 3px;
    text-decoration: none;
}

.roundButtonWhite{
  font-family: "neopro", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    fill:transparent;
    color: rgb(255, 255, 255);
    background-color: transparent;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: rgb(255, 255, 255) ;
    padding: 15px 46px 15px 46px;
    border-radius: 3px;
    text-decoration: none;
}

.roundButtonBlackBg{
  font-family: "neopro", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    fill:transparent;
    color: rgb(255, 255, 255);
    background-color: black;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: rgb(255, 255, 255) ;
    padding: 15px 46px 15px 46px;
    border-radius: 3px;
    text-decoration: none;
}

.newsPad{
  padding:  320px 35px 30px 35px;
 }

 .designPad{
  padding: 85px 40px 35px 0px
 }

 .bottomMargin{
  margin-bottom: 24px
 }

ul{
  list-style: none;
}

.sectionalClearSpace{
  margin-top: 2%; 
  margin-bottom: 2%;
}

.clearSpaceModel{
  padding: 0% 2%
}

.footerText{
  font-size: 14px !important;
}
.bottom_footer_link:after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 14px;
  background-color: #777;
  margin: 0 18px;
  vertical-align: middle;
}

.suvPad{
 padding-bottom: 10% !important;
}

.sedanPad{
  padding-bottom: 9.2% !important;
}

.whiteText{
  color: white !important;
}

.fullHeight{
  height: 100%
}

.mobWidth{
  width: 100% !important
}

.tabDesign{ 
  border-top-color: transparent !important;
  border-right-color : transparent !important;
  border-left-color : transparent !important;
  border-bottom-color: transparent !important;
}

.modelTextHome{ 
  font-size: 32px !important;
  font-weight: 600 !important;
  color: #000000 !important;
  line-height: 1.3 !important;
  opacity: .3 !important;
 
}

.nav-link.active{ 
  border-top-color: transparent !important;
  border-right-color : transparent !important;
  border-left-color : transparent !important;
  border-bottom-color: rgba(0,0,0,1) !important;
  opacity: 1 !important;

}

.nav-link:hover{ 
  border-top-color: transparent !important;
  border-right-color : transparent !important;
  border-left-color : transparent !important;
  border-bottom-color: rgba(0,0,0,1) !important;
  opacity: 1 !important;

}

.nav-link{ 
  border-top-color: transparent !important;
  border-right-color : transparent !important;
  border-left-color : transparent !important;
  border-bottom-color: rgba(0,0,0,0.3) !important;

}

.white-text{
  color:white !important
}

.dark-bg{
  background-color: rgba(53, 53, 53, 0.9);
}

.tools{
  margin-top: 60px;
    margin-bottom: 60px;
    padding: 0px 0px 0px 0px;
}

.halfHeight{
  height: 50vh ;
}

#serviceRequestText{
 padding: 8% 4%
}
#serviceRequestText1{
  padding: 8% 2%
 }

 #serviceRequestText2{
  padding-top: 8%
 }

 #serviceRequestTextrd{
  padding: 4% 4%
 }


 #serviceRequestTextDesign{
  padding: 1% 4%
 }


 .textPosition{
  justify-content: center;
  padding: 12% 0 ;
 }



 .textPosition1{
  position: absolute;
  right: 30%;
  top: 215% ;
 }

 .elementor .elementor-background-video-container {
  transition: opacity 1s;
  pointer-events: none;
}

.elementor .elementor-background-video, .elementor .elementor-background-video-embed, .elementor .elementor-background-video-hosted {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.elementor .elementor-background-video-container {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  direction: ltr;
}

.elementor .elementor-background-video-container {
  transition: opacity 1s;
  pointer-events: none;
}

 #textDesign{
  font-size: 48px;
    font-weight: 500;
    text-transform: none;
    line-height: 56px;
}

iframe {
  height: 100%;
  width: 100%;
  display: flex;
  border: none;
  background-color: #000;
  border-radius: 10px

}

#video{
  padding: 0% 5% !important;
}

#serviceRequestHeight{
  height: 500px;
  background:linear-gradient(rgba(241, 243, 244, 0.8), rgba(241, 243, 244, 0.8)), url('images/srbg.jpeg') center center;
 }

 #serviceRequestHeightCSR{
  height: 500px;
  background:linear-gradient(rgba(241, 243, 244, 0.8), rgba(241, 243, 244, 0.8)), url('images/cs.jpeg') center center;
 }

 #serviceRequestHeightDesign{
  height: 500px;
  background:linear-gradient(rgba(241, 243, 244, 0.8), rgba(241, 243, 244, 0.8)), url('images/design.jpg') center center;
 }

 #rdbg{
  height: 611px;
  background: url('images/rd.jpg') center center;
 }

 #serviceRequestHeight1{
  height: 85vh;
  background:linear-gradient(rgba(241, 243, 244, 0.8), rgba(241, 243, 244, 0.8)), url('images/vision.jpeg') center center;
  background-size: cover;
 }

 #loveSection{
  min-height: 400px;
  background:linear-gradient(rgb(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('images/love.jpeg') center center;
 }

 

 #careRequestHeight{
  height: 500px;
  background:linear-gradient(rgba(241, 243, 244, 0.8), rgba(241, 243, 244, 0.8)), url('images/Emgrand.jpg') center center;
 }


 #contactRequestHeight{
  height: 500px;
  background:linear-gradient(rgba(241, 243, 244, 0.8), rgba(241, 243, 244, 0.8)), url('images/ray.jpg') center center;
 }

.main{
  background:url('images/main.jpg');
  background-size: cover;
 }

 #srBar{
  padding-top: 6%
 }

 #crBar{
  padding: 6% 0%
 }

 #mtBar{
  padding: 4% 0%
 }


#formPlate{
  box-shadow: #ababab 0px 0px 10px 2px;
  position: relative;
  top: -28%
  
}

 .form_block .form_field {
  padding-bottom: 30px;
}

 .form_block .form_field .label {
  font-size: 15px;
  font-family: arial;
  color: #242424;
  display: block;
  padding-bottom: 10px;
}

.form_block .radio_btn {
  width: 100%;
  display: flex;
  margin-top: 15px;
}

.form_block .radio_btn .container {
  font-size: 15px;
  color: #000;
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#formPlate2{
  box-shadow: #ababab 0px 0px 10px 2px;
  position: relative;
  top: -35%
  
}


.maintainSelect{
  width: 100% !important;
    font-size: 20px !important;
    line-height: 28px !important;
    border-radius: 4px !important;
    padding: 18px 20px !important;
    box-sizing: border-box !important;
    border: 1px solid #000 !important;
    background-color: #ffffffd9 !important;
}


.heightField{
  min-height: 120px !important
}


#formPlate1{
  box-shadow: #ababab 0px 0px 10px 2px;
  position: relative;
  top: -23%
  
}

.maintainSelect:after{
    content: "";
    position: absolute !important;
    width: 0 !important; 
    height: 0; 
    right: 4px !important;
    top: 14px !important;
    pointer-events: none !important;

}


.shadow{
  box-shadow: #ababab 0px 0px 10px 2px;
}

.radius{
  border-radius: 4px
}

.flexDisplay{
  display: flex
}

.benefitsDivPad{
 padding: 14px;
 margin-top: 18px !important;
 margin-bottom: 18px !important;
}

.shortWidth{
  width: 48% !important
}

.longWidth{
   width: 48% !important
}


.blockDisplay{
  display: block
}

.benefitsPad{
  padding: 26px;
  margin-right: 32px !important;
}


.feat_highlights_detail {
  display: flex;
}

.feat_highlights_detail .feat_highlights_block.img_block {
  width: 55%;
}

.feat_highlights_detail .feat_highlights_block {
  width: 45%;
  position: relative;
}


.feat_highlights_block .feat_content_block {
  background-color: #fff;
  padding: 120px 60px;
  box-sizing: border-box;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -40px;
  width: 100%;
}

.feat_highlights_block .feat_content_block {
  padding: 90px 50px;
  left: -30px;
}

.feat_highlights_block .feat_content_block .feat_content_title {
  font-size: 30px;
  line-height: 38px;
  font-weight: 500;
  color: #000;
  margin: 0;
  text-align: initial;
}

.feat_highlights_block .feat_content_block .feat_content_title {
  font-size: 26px;
  line-height: 34px;
}

.feat_highlights_block .feat_content_block .feat_content_detail {
    display: block;
    font-size: 16px;
    line-height: 28px;
    color: #242424;
    padding-top: 25px;
    text-align: initial;
}



.newsWidth{
  width:31.5%;
  border-radius: 5px;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
  text-align:center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}


.warrantyPad{
  padding: 35px 0
}

.warrantyWidth{
  width:31.5%;
  border-radius: 5px;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
  text-align:center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 60px 50px 60px 50px;
}

.warrantyContainer{
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-fields{
  font-size: 15px !important;
  line-height: 25px;
  font-family: arial !important;
  height: 50px;
  border: 1px solid #e4e6e7 !important;
  border-top: 3px solid #e4e6e7 !important;
  border-radius: 4px !important;
  padding: 0 15px !important;
  box-sizing: border-box;
  width: 100%;
  background-color: #eae9e8 !important;
}

.formFont{
 font-family: Arial !important ;
}

.blackBtn{ 
  font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
    display: block;
    text-align: center;
    text-decoration: none;
    border-width: 1px;
    border-style: solid;
    border-color: initial;
    border-image: initial;
    padding: 10px 20px 8px;
    transition: all 0.3s ease-in-out 0s;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border-color: rgb(0, 0, 0);
  border-radius: 5px
}

.range{
  margin-top: 0px;
    margin-bottom: 0px;
    padding: 60px 0px 60px 0px;
}

.formViewPad{
   padding: 9% !important;
}

.inheritHeight{
  height: inherit
}
.creamBg{
  background-color: #EAE9E8
}

.creamRGB{
   background-color: rgb(239, 239, 239);
}

.featuredBanner1{
  background-image: url(./images/mj.jpeg);
  background-position: center;
  background-size: cover;
}

.featuredBanner2{
  background-image: linear-gradient(rgba(232, 233, 232,0.8), rgba(234, 233, 232,0.8)), url(./images/mj2.jpeg);
  background-position: center;
  background-size: cover;
}

.featuredBanner3{
  background-image: linear-gradient(rgba(53, 53, 53,0.9), rgba(53, 53, 53,0.9)), url(./images/Mirror-Right.jpg);
  background-position: center;
  background-size: cover;
}

#exploreHero{
  height: 80vh;
  background: url('./images/ok-v.jpg') center center;
  background-size: cover;

}



.background_360 {
  background-color: #EAE9E8;
}

#exploreButton{
  position:relative;
  top: 52%
}

.highlightImages{
  width:454;
  height:354;
}

#nameplatePosition{
   position:relative;
   top: 42%;

}

#nameplatePositionMob{
  position:relative;
  top: 64%;

}


.featuredBanner4{
  background-image: url(./images/Mirror-Left.jpg);
  background-position: center;
  background-size: cover;
}



.featuredText{
  padding: 10% 15%;
}


.car-spec{
  padding: 20px 46px;
    border-top: 1px solid #f1f3f4;
    margin: 40px 0;
    border-bottom: 1px solid #f1f3f4;
    width: 92%;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    border-color: #000;
}

.car-spec-img {
  padding-right: 15px;
}

.car-spec .car-spec-1 {
  font-size: 15px;
  line-height: 20px;
}

.car-speci-1 {
  font-size: 15px;
  line-height: 20px;
  color: white;
}

.divider {
  width: 1px;
  height: 30px;
  display: inline-block;
  background-color: rgb(0, 0, 0);
  margin: 0px 6px;
}
.divider1 {
  width: 1px;
  height: 30px;
  display: inline-block;
  background-color: rgb(255, 255, 255);
  margin: 0px 6px;
}

.car-sub-title{
  font-size: 20px;
    padding-left: 15px;
    font-weight: 400;
}

.car-sub-tittle {
  font-size: 20px;
  padding-left: 15px;
  color: rgb(241, 243, 244);
  font-weight: 400;
}



.carTitle img, .bright {
  filter: brightness(0) !important; 
}

.linksSection{
  padding-bottom: 4.5%;
  padding-top: 2.5%
}

.divider{
  height: 20px;
    background-color: #949494;
    margin: 0 10px!important;
    margin: 0;
    direction: ltr;
    width: 1px;
    
}



.car-specific {
    width:92%;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: 20px 46px;
    border-top: 1px solid rgb(241, 243, 244);
    margin: 40px 0px;
    border-bottom: 1px solid rgb(241, 243, 244);
}

.car-spec-detail {
  display: flex;
  align-items: flex-start;
  padding: 20px 0px 4px;
}

.car-detail-btns a {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  display: block;
  text-align: center;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: initial;
  padding: 10px 20px 8px;
  transition: all 0.3s ease-in-out 0s;
}

.car-detail-btns1 a {
  font-size: 15px;
  line-height: 26px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgb(255, 255, 255) !important;
  display: block;
  text-align: center;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: initial;
  padding: 10px 20px 8px;
  transition: all 0.3s ease-in-out 0s;
}

.car-detail-btns, .car-detail-btns1 {

  margin-top: 40px;
}

.detail-btn.buy-now-btn a {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255) !important;
  border-color: rgb(0, 0, 0);
}

.detail-btn1.buy-now-btn a {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-color: rgb(255, 255, 255);
}

.banner-btn a {
  background-color:white;
  border-radius: 5px;
  border-style: none !important;
  font-size: 11px !important;
  width: 123% !important;
  color: black !important
}

 .finance-btn a {
  background-color:#D4DCDE99;
  border-radius: 5px;
  border-style: none !important;
}

.explore-btn2 a {
  background-color:#D4DCDE99;
  border-radius: 5px;
  border-style: none !important;
}
.explore-btn2b a {
  background-color:#000000;
  border-radius: 5px;
  border-style: none !important;
}


.car-detail-btns1 a{
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  display: block;
  text-align: center;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: initial;
  padding: 10px 9px 8px;
  transition: all 0.3s ease-in-out 0s;
}

.wrapper_360 {
  position: relative;
  padding: 60px 0 40px;
  box-sizing: border-box;
}

.wrapper_360 .tab_360 {
  position: absolute;
  top: 60px;
  right: 50px;
  display: flex;
}

.detail_page_title img {
  max-width: 100%;
  vertical-align: super;
}

.detail_page_title {
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  color: #000;
}



.toolHeading{
    color: var(--e-global-color-primary);
    font-family: "neopro", Sans-serif;
    font-size: 34px;
    font-weight: 500;
    text-transform: uppercase;
}

.marginTopStandard{
  margin-top: 40px !important;
}

.marginTopStandard1{
  margin-top: 20px !important;
}

.marginBottomStandard{
  margin-bottom: 40px !important;
}

.cardDesign{
  
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 20px 0px 0px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 80px 0px;
    border:0 !important;

}

.One, .Two{
display: flex;
}

.cardImg{
  .elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 4px;
    box-shadow: none;
}
}


.spec_360_block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.spec_360_block .spec_360_list {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.spec_360_block .spec_360_list li::before {
  content: '';
  width: 1px;
  height: 42px;
  background-color: #000;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.spec_360_block .spec_360_cta {
  width: auto;
}

.spec_360_block .spec_360_cta {
  width: auto;
}


.spec_360_block .spec_360_list li strong {
  font-size: 22px;
  line-height: 28px;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
}

.spec_360_block .spec_360_list li {
  padding: 0 40px;
  box-sizing: border-box;
  position: relative;
}

.spec_360_block .spec_360_cta a {
  text-decoration: none;
  font-size: 17px;
  line-height: 15px;
  text-transform: uppercase;
  color: #f1f3f4;
  background-color: #686868;
  padding: 12px 20px;
  box-sizing: border-box;
  display: block;
  border-radius: 3px;
  transition: .3s linear;
}

.spec_360_block .spec_360_cta a {
  background-color: #000;
}

.spec_360_block .spec_360_cta a:hover {
  background-color: #575757;
}

.topBanner{
  background-image: url(./images/ok.jpg);
  background-position: center;
  background-size: cover;
  height:500px;
}

.topBanner1{
  background-image: url(./images/g-1.jpeg);
  background-position: center;
  background-size: cover;
  height:500px;
}

.topBanner2{
  background-image: url(./images/monjaro.jpeg);
  background-position: center;
  background-size: cover;
  height:500px;
}

.topBanner3{
  background-image: url(./images/coolray.jpg);
  background-position: center;
  background-size: cover;
  height:500px;
}



.nav-font{
  font-weight: 500 !important;
  color: black !important;
  box-sizing: border-box !important;
    transition: .3s ease-in-out !important;
    font-family: "neopro" !important
}

.gFont{
  font-family: "neopro" !important
}

.nav-padding{
  padding-left: 10px;
  padding-right: 10px;
}

#geely-bg{
  height:64px;
  background-color: black !important
}

#logo{ 
  width: 15% !important;
}

.defaultLink{
    text-decoration: none;
    color: currentColor;
    cursor: pointer;
}

.defaultButton {
  background: transparent;
  border-color: transparent;
  text-decoration: none;
  color: currentColor;
  padding: 0;
  height: 48px;
  border: 1px solid;
  cursor: pointer;
  user-select: none;
    border-radius: 6px;
    font-size: 16px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 48px;
    border: 1px solid;
    cursor: pointer;
    color: currentColor;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 20px;
    border-radius: 6px;
    font-size: 16px;
    line-height: 1;
    -webkit-transition: all .6s cubic-bezier(.38,.015,0,.995);
    transition: all .6s cubic-bezier(.38,.015,0,.995);
    width:203px;
}

.defaultButton:hover{
  background-color: #215469;
  color:white
}

.buttonText{
  white-space: nowrap;
  color: white;
}

.borderlessBtn{
    padding: 0 20px !important;
    border-color: #fff !important;
    color: black !important
}

.blackBorderBtn{
  padding: 0 20px !important;
  border-color: #000000 !important;
}

.sedanBG, .suvBG{
  height:calc(40.66667vw + 3.33333px);
 
 position: relative;
 overflow: hidden;
}

.sedanBgImg{
  background: url('./images/emgrand.jpeg')center center;
  background-size: cover;
  --ratio: 2.4;
  overflow: hidden;
  width:100%;
  height:100%; 
  -webkit-transition: -webkit-transform 1s ease 0s;
    transition: -webkit-transform 1s ease 0s;
    transition: transform 1s ease 0s;

}

.sedanBgImg:hover{
  transform: scale(1.1);
  transition: transform 1s ease-in-out 0s,-webkit-transform 1s ease-in-out 0s;
 
}

.suvImg1{
  background: url('./images/star.jpeg')center center;
  background-size: cover;
  --ratio: 2.4;
  overflow: hidden;
  width:100%;
  height:100%; 
  -webkit-transition: -webkit-transform 1s ease 0s;
    transition: -webkit-transform 1s ease 0s;
    transition: transform 1s ease 0s;
}

.suvImg1:hover, .suvImg2:hover, .suvImg3:hover, .suvImg4:hover, .suvImg5:hover {
  transform: scale(1.1) !important;
  transition: transform 1s ease-in-out 0s,-webkit-transform 1s ease-in-out 0s;
}

.suvImg2{
  background: url('./images/coolray.jpeg')center center;
  background-size: cover;
  --ratio: 2.4;
  overflow: hidden;
  width:100%;
  height:100%; 
  -webkit-transition: -webkit-transform 1s ease 0s;
    transition: -webkit-transform 1s ease 0s;
    transition: transform 1s ease 0s;
}

.suvImg3{
  background: url('./images/Tugella.jpeg')center center;
  background-size: cover;
  --ratio: 2.4;
  overflow: hidden;
  width:100%;
  height:100%; 
  -webkit-transition: -webkit-transform 1s ease 0s;
    transition: -webkit-transform 1s ease 0s;
    transition: transform 1s ease 0s;
}

.suvImg4{
  background: url('./images/azkara.jpeg')center center;
  background-size: cover;
  --ratio: 2.4;
  overflow: hidden;
  width:100%;
  height:100%; 
  -webkit-transition: -webkit-transform 1s ease 0s;
    transition: -webkit-transform 1s ease 0s;
    transition: transform 1s ease 0s;
}

.suvImg5{
  background: url('./images/vango.jpeg')center center;
  background-size: cover;
  --ratio: 2.4;
  overflow: hidden;
  width:100%;
  height:100%; 
  -webkit-transition: -webkit-transform 1s ease 0s;
    transition: -webkit-transform 1s ease 0s;
    transition: transform 1s ease 0s;
}


.serviceContentBlock{
  position: relative; 
  top: 30%
}


.aftersalesBgContainer{
 height: 346px;
 position: relative;
 overflow: hidden;
}

.aftersalesBg{
  background-image: url('./images/engine.jpeg');
  background-position: 50%;
  background-size: cover;
  width:100%;
  height:100%
}


.quickCallContainer{
  height: 346px;
  overflow: hidden;
 }
 
 .quickCallBg{
   background-image: url('./images/hotline.jpg');
   background-position: 50%;
   background-size: cover;
   width:100%;
   height:100%
 }

 .quoteBgContainer{
  height: 182px;
  overflow: hidden;
 }
 
 .quoteBg{
   background-image: url('./images/finance.jpg');
   background-position: 50%;
   background-size: cover;
   width:100%;
   height:100%
 }

 .testDriveContainer{
  height: 182px;
  overflow: hidden;
 }
 
 .testDriveBg{
   background-image: url('./images/emg-int.jpeg');
   background-position: 50%;
   background-size: cover;
   width:100%;
   height:100%
 }

.transBg{
 background-color: transparent !important;
 border-bottom-color: black !important;
 border-right-color: transparent !important;
 border-left-color: transparent !important;
 border-top-color: transparent !important;
}

.transBg1{
  background-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
 }

 .accordion-button:not(.collapsed) {
    color: black !important
 }

.accordBorder{
  border-bottom-color: black ;
}
 .signatureSection{
  padding-top: 21px; 
  padding-bottom: 23px;
 }




.headingText{ 
  font-size: 48px ;
  line-height: 1;
}

.btnContainer{
  position: relative;
    top: 85%;
}

.newsSection{
  padding-top: 40px ;
}

.newsSpace{
  padding: 0 calc(4.16667vw + 0.33333px);
}

.newsHeadingText{ 
    font-size: 32px;
    margin-top: 21px;
    line-height: 1.3;
}

.linkDesign{
  text-decoration: none;
  color: black
}

.descriptionContainer{
  font-size: 16px;
  line-height: 1.5;
  margin-top: 15px;
}

.greyBg{
  background-color: #EAE9E8;
}

.newsImgHolder{
  max-width: 558px;
  height: calc(16.66667vw + 1.33333px);
  overflow: hidden;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {

  background-color: white !important;
}

.newsImg{ 
  width:100%;
  height: calc(16.66667vw + 1.33333px);
  cursor: pointer;
  -webkit-transition: all 1s;
  transition: all 1s;
  -webkit-transform-origin: center center;
  transform-origin: center center;
 
  background-position: 50%;
  background-size: cover;
}

.newsImg0{
  background-image: url('./images/news1.jpeg');
}

.newsImg1{
  background-image: url('./images/news2.jpeg');
}

.newsImg2{
  background-image: url('./images/news3.jpeg');
}

.newsImg3{
  background-image: url('./images/news4.jpeg');
}

.newsImg4{
  background-image: url('./images/news5.jpeg');
}

.newsImg:hover{
  transform: scale(1.1);
  transition: transform 1s ease-in-out 0s,-webkit-transform 1s ease-in-out 0s;
}

.swiper-container {
  width: 480px;
}


footer{
  background-color: rgb(239, 239, 239);
}

.footerSection{
  width: 100%;
  padding: 2% 7%;
    background-color: rgb(239, 239, 239);
}

.footerHeading{
  font-weight: 400 !important;
  font-size: 16px !important;
  text-transform: capitalize;
}

.mediaIcons{
  padding-right:0.5%;
  padding-left: 0.5%;

}

.feature_detail_inner .feature_main_detail {
  padding-top: 40px;
}

.feature_main_detail {
  display: flex;
  align-items: center;
  padding-top: 60px;
}

.feature_main_detail .image_block {
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.feat_highlights_carousel.owl-carousel {
  width: 94%;
  margin: auto;
  margin-top: 60px;
}

.feature_main_detail .image_block .left_col {
  width: 25%;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.feature_main_detail .image_block .right_col img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature_main_detail .image_block .right_col {
  width: 72%;
}

.feature_main_detail .detail_content_col {
  background-color: #eae9e8 !important;
}

.feature_main_detail .detail_content_col {
  width: 45%;

  padding: 100px 60px;
  box-sizing: border-box;
}

.feature_main_detail .detail_content_col .detail_content_title {
  font-size: 28px;
  line-height: 38px;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
}

.feature_main_detail .detail_content_col .highlighted_content {
  padding-top: 30px;
}

.feature_main_detail .detail_content_col .highlighted_content .txt {
  font-size: 17px;
  line-height: 30px;
  color: #242424;
  font-family: arial;
}

.feature_detail_carousel.owl-carousel {
  margin-top: 50px;
}

.features_carousel_blocks .detail_block {
  position: relative;
  display: block;
  text-decoration: none;
}

.features_carousel_blocks .detail_block .feat_carousal_content {
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .5s ease-in-out;
}

.features_carousel_blocks .detail_block .feat_carousal_content .content_inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px;
  box-sizing: border-box;
}

.features_carousel_blocks .detail_block .feat_carousal_content .feat_carousel_titile {
  font-size: 22px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  margin: 0;
}

.features_carousel_blocks .detail_block .feat_carousal_content .feat_carousel_titile {
  font-size: 22px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  margin: 0;
}

.feature_detail_inner {
  padding: 90px 0 70px;
  box-sizing: border-box;
}

.detail_page_container{
  max-width: 1300px;
  margin: auto;
}

.detail_page_title {
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  color: #000;
}

.spec_table_wrapper {
  padding-top: 40px;
}

.spec_table {
    width: 100%;
    border: 1px solid #e6eef3;
    border-collapse: collapse;
}

.spec_table .upper_body {
    border: 0;
}

.spec_table .spec_model_info.first_col {
    background-color: #e6eef3;
    border-bottom: 1px solid #fff;
}

.spec_table .spec_model_info.first_col, .spec_table .lower_body tr th {
    background-color: #eae9e8 !important;
}

.spec_table .spec_model_info {
  
    text-align: center;
    padding: 30px 20px;
    box-sizing: border-box;
    border: 1px solid #e6eef3;
    background-color: #fff;
}

.spec_table .spec_model_info {
    width: 20% !important;
}

.spec_table .spec_model_info .spec_model_img img {
    max-width: 100%;
}

.spec_table .spec_model_info .spec_model_name {
    font-size: 16px;
    line-height: 24px;
    color: #000;
    margin: 0;
}

.spec_table .spec_model_info .spec_model_cta a {
    font-size: 14px;
    line-height: 14px;
    padding: 10px 20px;
    min-width: 10em;
    border-radius: 3px;
    color: #fff;
    background-color: #0070b7;
    text-decoration: none;
    display: inline-block;
    margin-top: 20px;
    transition: .3s ease-in-out;
    border: 1px solid #0070b7;
}

.spec_table .spec_model_info .spec_model_cta a {
    background-color: #000 !important;
    border-color: #000 !important;
}

.compareDesign{
  width: 50% !important; 
}

.spec_table .lower_body {
  border: 0;
}

.spec_table .lower_body tr th {
  background-color: #e6eef3;
  border: 1px solid #e6eef3;
  border-bottom: 1px solid #fff;
  vertical-align: middle;
  font-weight: 500;
}

.spec_table .spec_label {
  font-size: 15px;
  line-height: 25px;
  color: #3a3a3a;
  text-align: inherit;
  padding: 15px 25px;
}

.spec_table .lower_body tr:nth-child(odd) td {
  background-color: #eae9e8 !important;
}

.spec_table .lower_body tr td {
  background-color: #fff;
  border: 1px solid #e6eef3;
  vertical-align: middle;
}

.spec_table .spec_value {
  font-size: 15px;
  line-height: 25px;
  color: #000;
  text-align: center;
  padding: 15px 25px;
}

.mBottom{
  margin-bottom: 2%;
}
.feat_carousal_content {
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .5s ease-in-out;
}

.features_carousel_blocks .detail_block .feat_carousal_content .content_inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px;
  box-sizing: border-box;
}

.features_carousel_blocks .detail_block .feat_carousal_content .feat_carousel_titile {
  font-size: 22px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  margin: 0;
}

.features_carousel_blocks .detail_block .feat_carousal_content .feat_carousel_txt {
  font-size: 16px;
  line-height: 24px;
  font-family: arial;
  text-align: center;
  color: #fff;
  margin: 0;
  padding-top: 10px;
}

.socialPad{ 
  padding: 1% 0%
}

.hashBg{
  background-color: #DFDFDF;
}

.exploreText{
 
    position: relative;
    top: 38%;

}

.footerLine{
  display: block;
  width: 85vw !important;
  height: 1px;
  background: rgba(0,0,0,.1);
  margin-left: calc(-4.16667vw - 0.33333px);
  margin-top: 24px !important;
  width: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #000;
}


.paddingOffMob{
  padding: 0px !important;
}


.centerMob{
  text-align:left;
}

@-webkit-keyframes infoIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes infoIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes infoOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes infoOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
#hero {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  height: 470px; 
  background-color: rgb(239, 239, 239);
}



#hero:before {
  display: block;
  content: "";
  padding-top: 50%;
}

.hero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 470px;
}

.hero figure {
  margin: 0;
  padding: 0;
  width: 10%;
  height: 100%;
  position: relative;
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%;
  -webkit-transition: 0.8s linear;
  -o-transition: 0.8s linear;
  transition: 0.8s linear;
  -webkit-filter: saturate(25%);
  filter: saturate(25%);
}


.hero figure:not(.on):hover {
  cursor: pointer;
}
.hero figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.hero figure.on {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-transition: 0.8s linear;
  -o-transition: 0.8s linear;
  transition: 0.8s linear;
  z-index: 20;
  -webkit-filter: saturate(100%);
  filter: saturate(100%);
}
.hero figure div {
  -webkit-animation: infoOut;
  animation: infoOut;
}

.hero figure.on div {
  position: absolute;
  bottom: 0%;
  padding: 20px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.9);
  -webkit-animation: infoIn 1.2s;
  animation: infoIn 1.2s;
}
.hero figure.on div h2 {
  font-size: 3em;
  width: 100%;
}

.hero figure.on div p {
  font-size: 1.7em;
  width: 100%;
  margin-bottom: 0;
}
.hero figure.on div a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  transition: 0.3s ease;
}
.hero figure.on div a:after {
  content: "\f054";
  font-family: "FontAwesome";
  margin-left: 5px;
  display: inline-block;
  transition: 0.3s ease;
}
.hero figure.on div a:hover::after {
  transform: translateX(3px);
  transition: 0.3s ease;
}
.hero figure.on div a:hover {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.9), 0 2px 3px rgba(0, 0, 0, 0.7);
  transition: 0.3s ease;
}

.othersectionimg{
  width: 167px !important;
  height: 158px !important;
}

.othersectionImg{
  width: 519px !important;
  height: 516px !important;
}



@media screen and (min-width: 1025px) and (max-width: 1320px){
  .feature_detail_carousel.owl-carousel {
    width: 90%;
    margin: auto;
    margin-top: 40px;
}
}

@media screen and (max-width: 800px) {
  .hero {
    font-size: 1.3vw;
  }
  .hero figure.on div {
    padding: 20px;
  }
}
@media screen and (max-width: 600px) {
  .hero {
    font-size: 1.8vw;
  }
  .hero figure.on div {
    bottom: 5%;
    left: 0;
    right: 0;
    width: auto;
  }
}


.clearBottomDesktop{
  margin-bottom: 1.5% !important
}

.clearBottomDesktop1{
  margin-bottom: 4% !important
}


.newsPadding{
  padding-top: 2%;
  padding-bottom:2%;
  padding-right: 8% !important;
  padding-left: 8% !important;
} 


.box-marg{
  margin-bottom: 5%
}

.gellyWidth{
  width: 70%
}

.ksaStyleDesk{
  background-color: rgba(0,0,0,0.6) !important;
  right: 57% !important;
  bottom: 4.25rem !important;
  left: 12% !important;
  padding-right: 3% !important;
  padding-left: 3% !important;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  text-align: left !important;
}


.ksaStyle{
  background-color: rgba(0,0,0,0.6) !important;
  padding-bottom: 11% !important;
  bottom: 4.25rem !important;
  padding-top: 11% !important;
  padding-right: 3% !important;
  padding-left: 3% !important;
  right:5% !important;
  left: 5% !important;
  text-align: left !important ;
}

@media(max-width:991px){

 .navHeight{
  display: none !important;
 }

 .gellyWidth{
  width: 100%
}

.banner-btn a {
  background-color: #fff;
        border-radius: 5px;
        border-style: none !important;
        font-size: 9px !important;
        padding: 1px 7px 1px !important;
        width: 107% !important;

}


 .car-detail-btns a {
    font-size: 13px !important 
 }
  
  
  #textDesign{
    font-size: 22px;
  }

  .textPosition{
    padding-top: 25%
  }
  .textPosition1{
    right: 0%
  }

  #loveSection{
    min-height: 250px;
   }
  
   .spec_360_container{
      display:none;
   }

  .csrHero{
    position: relative;
    top: 43%;
}

.fullWidthExt{
  width: 101% !important
}

  #serviceRequestHeightCSR{
    height: inherit;
    background:linear-gradient(rgba(241, 243, 244, 0.8), rgba(241, 243, 244, 0.8)), url('images/cs.jpeg') center center;
   }

   #serviceRequestHeightDesign{
    height: 40vh;

   }

  .sedanBG, .suvBG{
    height:562px;
  }

  .visionPadding{
    padding-right:4%;
    padding-left: 4%;
  }

  #serviceRequestHeight1{
    height: 50vh;
   }

  .mobTextCenter{
    text-align: center
  }

  .clearSpaceCarouselVideo{
    margin-top: 0px !important;
  }

  .vidTitle{
    font-size: 24px;
  }
  
  
  .clearSpaceCarouselBottomVideo{
    margin-bottom: 0px !important;
  }

  #serviceRequestText2{
    padding-top: 2%
   }
  

  .mobileSideNav{
    display:block;
  }

  .car-detail-btns, .car-detail-btns1 {

    margin-top: 15px;
  }

  .desktopSideNav{
    display:none;
  }

  .side_inner_block {
    padding: 30px 0px !important;
    box-sizing: border-box;
}

.side_bottom_block {
  padding: 20px 40px;
 
  position: relative;
  bottom: -2%;

}

  .sideNav{
    width: 80% !important;
  }

  #careRequestHeight, #serviceRequestHeight{
    height: 220px;

   }

 .shortWidth, .longWidth, .warrantyWidth, .newsWidth, .size-full{
  width: 100% !important;

 }

 .news-tittle {
  flex: 2.7 1;
}
  
.title {
  font-size: 20px;

}

   .networkBox, .networkBox1{
    width: 100% !important;
   }


.networkBoxes{
 padding: 0 15px ;
  }

.networkBoxesBelow{
  padding-bottom: 15px
}

.servicePad {
padding-bottom: 0px;
Padding-top: 16px;
}

   .geelyMap{
    width:100%;
    object-fit: cover;
   }

   #formPlate{
     top: -11%
   }

.clearTopMob{
  margin-top: 10% !important;
}

  .car-specific , .car-spec{
      width: 100%;
  }

  .socialPad {
    padding: 3% 0%;
}

.mediaIcons{
  margin-right: 4% ;
}

h3{
  font-weight: 400!important;
  font-size: 22px !important;
}

  .main-nav{
    bottom: 36%;
    left: 52%;
  }

  .accordion-button{
    border-bottom-color: transparent !important;
  }

  .owl-dot{
    width:15px !important;
    height:15px !important;
    color: #EAE9E8 !important;
    background-color: #EAE9E8
    
  }



  .owl-dot.active{
    color:black !important;
  }

  .owl-prev , .owl-next{
      display:none;
  }

  .clearBottom{
    margin-bottom: 4% !important
  }

  .car-spec, .car-spec-1, .car-specific {
    font-size: 20px !important;
    padding-left: 8px !important;
    padding-right: 2px !important;
 
  }

  .borderBottom{
    border: 1px solid ;
    border-left-color: #eeeeee ;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: #eeeeee;
    
  }

  #exploreHero{
    height: 63vh;
  }

  .borderRight{
    border: 1px solid ;
    border-left-color: transparent ;
    border-right-color: #eeeeee;
    border-bottom-color: transparent;
    border-top-color: transparent;
    
  }

  .toolsPadding{
    padding-top: 7%;
    padding-bottom:7%;
    padding-right:4%;
    padding-left: 4%
  }

  .newsPad{
    padding-top: 220px
  }
  
  .designPad{
    padding-top: 145px
  }

  .mobPadding{
    padding: 0 20px;
  }

  .featuredText{
    padding: 10% 5%;
  }


.featuredBanner1, .featuredBanner4{
  min-height: 360px;
}

  .paddingOffMobile{
    padding: 0px !important;
  }

  .fullWidth{
    width:102.8% !important;
  }

  .centerMob{
     text-align:center;
  }

  .headingText, .newsHeadingText{
    font-size: 24px
  }

  .newsImg, .newsImgHolder{
    height: calc(56vw - 10px)
  }

  .mobWidth{
    width: 103% !important;
  }

  #logo{
    width: 22% !important
  }
  .toolsPadding{
    padding-top: 3%;
    padding-bottom:3%;
  }

  .highlightImages{
    width:454;
    height:471;
  }

  .marginTopStandard{
    margin-top: 7px !important;
  }
  
  .marginBottomStandard{
    margin-bottom: 7px !important;
  }

  .tools{
    margin-bottom: 0px;
  }

  .car-sub-title, .car-sub-tittle{
    display: block;
    padding-left: 0;
    padding-top: 4px;
  }

  .feat_highlights_carousel .feat_highlights_detail {
    flex-direction: column !important;
}

  .feat_highlights_detail .feat_highlights_block.img_block {
    width: 100% !important;
}

.feat_highlights_detail .feat_highlights_block {
  width: 100% !important;
}

.feat_highlights_block .feat_content_block {
  padding: 18px 27px;
left:0 !important;
transform: none;
position: relative;
}

.feat_highlights_block .feat_content_block .feat_content_detail {
padding-top: 0px;
}

.feature_detail_inner .feature_main_detail {
  flex-direction: column;
  padding-top: 20px;
}

.feature_main_detail .image_block {
  width: 100%;
}

.feature_main_detail .detail_content_col {
  width: 100%;
  padding: 30px 20px;
  order: 1;
  margin-top: 15px;
}


.othersectionimg{
  width: 98px !important;
  height: 93px !important;
}

.feature_main_detail .image_block .right_col {
  width: 69%;
}

.feature_main_detail .image_block .right_col img {

}

.feature_main_detail .image_block .left_col {
  grid-gap: 12px;
}

.btn-desk{
  text-align: center;
}

.othersectionImg{

  height: 300px !important;
}

.spec_table_block {
  overflow-x: scroll;
}

.spec_table {
  overflow-x: scroll;
}   

.spec_table .spec_model_info {
  padding: 20px 15px;
  min-width: 215px;

}

.spec_table .fixed_cell {
  position: sticky;
  left: -2px;
  min-width: 91px;
}

.btn-desk{
  text-align: left;
}

.box-marg{
  margin-bottom: 2%
}


}

@media screen and (max-width: 884px) {
  .car-spec, .car-spec-1, .car-specific {
    font-size: 17px !important;
  }
  .banner-btn a {
          width: 133% !important;
  
  }

  .main-nav{
    bottom: 29%;
    left: 52%;
  }
}
@media (min-height: 600px) and (max-height:820px){ 
  #exploreHero {
    height: 93vh ;
}
}

@media (min-height: 500px) and (max-height:600px){ 
  #exploreHero {
    height: 100vh;
}
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media (max-width: 767px) {
    .elementor-1853 .elementor-element.elementor-element-2788fab > .elementor-container {
        min-height: 450px;
    }
    .banner-btn a {
      width: 48% !important;
  
  }

  .ksaStyleDesk {
    bottom: -0.75rem !important;
}

.capMobile{
  font-size: 11px !important
}
}


@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }


}

@media screen and (min-width: 620px) {
  #exploreHero {
    height: 99vh;
}
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
